function PopUp () {
  var state = false;
  var time_effect = 200;

  addJqueryListeners = function () {
    $('.popup .modal .close').click(hide);
    $('.popup-label').click(show);
    $(document).keyup(function(e) {
      if (e.key === 'Escape' && state === true) {
        hide();
      }
    });
  };

  var showWidthDelay = function () {
    setTimeout(function() {
      show();
    }, 1500);
  };

  var show = function () {
    $('.popup').show(time_effect);
    $('.popup-label').addClass('show');
    state = true;
  };

  var hide = function () {
    $('.popup').hide(time_effect);
    $('.popup-label').removeClass('show');
    state = false;
  };

  var init = function () {
    addJqueryListeners();
    showWidthDelay();
  };

  init();
}

$(document).ready(function () {
  PopUp();
});
